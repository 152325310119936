<template>
  <form
    class="px-6 pt-6 pb-8 bg-gray-100"
    novalidate
    @submit.prevent
  >
    <label class="block relative">
      <span class="text-gray-800 text-sm">Name</span>
      <input
        v-model="$v.formResponses.name.$model"
        type="text"
        class="form-input mt-1 w-full"
        placeholder="Name"
      />
    </label>
    <ErrorsInline v-if="errors">
      <span
        v-if="!$v.formResponses.name.required"
      >This field is required.</span>
    </ErrorsInline>

    <label class="block relative mt-6">
      <span class="text-gray-800 text-sm">VAT Number</span>
      <input
        v-model="$v.formResponses.vatNumber.$model"
        type="text"
        class="form-input mt-1 w-full"
        placeholder="VAT Number"
      />
    </label>

    <fieldset class="mt-6">
      <legend class="font-medium">
        Address
      </legend>

      <label class="block relative mt-2">
        <span class="text-gray-800 text-sm">Address Line 1</span>
        <input
          v-model="$v.formResponses.address.line1.$model"
          type="text"
          class="form-input mt-1 w-full"
          placeholder="Address Line 1"
        />
      </label>

      <label class="block relative mt-6">
        <span class="text-gray-800 text-sm">Address Line 2</span>
        <input
          v-model="$v.formResponses.address.line2.$model"
          type="text"
          class="form-input mt-1 w-full"
          placeholder="Address Line 2"
        />
      </label>

      <div class="md:flex justify-between -mx-2">
        <label class="block relative mt-6 md:w-1/2 px-2">
          <span class="text-gray-800 text-sm">Town/City</span>
          <input
            v-model="$v.formResponses.address.city.$model"
            type="text"
            class="form-input mt-1 w-full"
            placeholder="Town/City"
          />
        </label>

        <label class="block relative mt-6 md:w-1/2 px-2">
          <span class="text-gray-800 text-sm">State/Province</span>
          <input
            v-model="$v.formResponses.address.state.$model"
            type="text"
            class="form-input mt-1 w-full"
            placeholder="State"
          />
        </label>
      </div>

      <div class="md:flex justify-between -mx-2">
        <label class="block relative mt-6 md:w-1/2 px-2">
          <span class="text-gray-800 text-sm">Postcode</span>
          <input
            v-model="$v.formResponses.address.postalCode.$model"
            type="text"
            class="form-input mt-1 w-full"
            placeholder="Postcode"
          />
        </label>

        <label class="block relative mt-6 md:w-1/2 px-2">
          <span class="text-gray-800 text-sm">Country</span>
          <select
            v-model="$v.formResponses.address.country.$model"
            class="form-select block w-full mt-1"
          >
            <option
              v-for="country in countries"
              :key="country.value"
              :value="country.value"
            >
              {{ country.text }}
            </option>
          </select>
        </label>
      </div>
    </fieldset>

    <section class="mx-auto text-center">
      <BaseButton
        class="mt-8"
        :loading="loading"
        @click="submit"
      >
        Save changes
      </BaseButton>

      <div class="mt-2 flex justify-center">
        <ErrorsInline
          v-if="empty && uiState === 'submit clicked'"
          class="text-center"
        >
          Please fill in the required fields above
        </ErrorsInline>
      </div>
    </section>
  </form>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import countries from '@groupResources/lists/countries.json'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    ErrorsInline
  },

  mixins: [validationMixin],

  data() {
    return {
      uiState: 'submit not clicked',
      loading: false,
      errors: false,
      empty: true,
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,

      formResponses: {
        name: null,
        vatNumber: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          state: null,
          country: 'GB'
        }
      },
      countries
    }
  },

  computed: {
    ...mapGetters({
      savedName: 'organisations/name',
      savedVatNumber: 'organisations/vatNumber',
      savedAddress: 'organisations/address'
    })
  },

  validations: {
    formResponses: {
      name: {
        required
      },
      vatNumber: {},
      address: {
        line1: {},
        line2: {},
        city: {},
        postalCode: {},
        state: {},
        country: {}
      }
    }
  },

  mounted() {
    this.formResponses.name = this.savedName
    this.formResponses.vatNumber = this.savedVatNumber
    this.formResponses.address = Object.assign({
      line1: null,
      line2: null,
      city: null,
      postalCode: null,
      state: null,
      country: 'GB'
    }, this.savedAddress)

    if (this.savedAddress && this.savedAddress.country === null) {
      this.formResponses.address.country = 'GB'
    }

    this.$v.formResponses.$touch()
  },

  methods: {
    submit() {
      this.empty = !this.$v.formResponses.$anyDirty
      this.errors = this.$v.formResponses.$anyError
      this.uiState = 'submit clicked'
      if (this.errors === false && this.empty === false) {
        this.loading = true

        let organisation = this.formResponses

        // We default the address country to GB.
        // If any of the other address fields are edited we send the address to the API.
        // If they are all null, we dont send the address.
        const { country, ...restOfAddress } = this.formResponses.address
        if (Object.values(restOfAddress).every(value => value === null)) {
          organisation = {
            ...this.formResponses,
            address: null
          }
        }

        this.$store.dispatch('organisations/updateOrganisation', organisation)
          .then(() => {
            this.$emit('close')
            this.uiState = 'form submitted'
          })
          .catch(() => {
            this.uiState = 'submit not clicked'
          })
      }
    }
  }
}
</script>
