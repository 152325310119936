<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <h2 class="text-lg">
          Your profile
        </h2>
      </div>

      <EditProfileButton />
    </header>

    <section>
      <dl class="flex flex-wrap mt-8 text-sm">
        <dt class="w-1/3 py-4 text-gray-500">
          Full name
        </dt>
        <dd class="w-2/3 py-4">
          {{ name }}
        </dd>

        <dt class="w-1/3 py-4 text-gray-500 border-t border-b">
          Email
        </dt>
        <dd class="w-2/3 py-4 border-t border-b">
          {{ email }}
        </dd>

      <!-- @NOTE 2019-11-11 We don’t store this data yet -->
      <!-- <dt class="w-1/3 pt-4 text-gray-500 border-t">
        Position
      </dt>
      <dd class="w-2/3 pt-4 border-t">
        Chief
      </dd> -->
      </dl>
    </section>

    <section>
      <Notifications />
    </section>

    <SendExamsTemplate />
  </Basecard>
</template>

<script>
import EditProfileButton from '@components/EditProfileButton'
import Notifications from '@components/Settings/Notifications'
import SendExamsTemplate from '@components/Settings/SendExamsTemplate'

import { mapGetters } from 'vuex'

export default {
  components: {
    EditProfileButton,
    Notifications,
    SendExamsTemplate
  },

  computed: {
    ...mapGetters({
      name: 'employers/name',
      email: 'employers/email'
    })
  }
}
</script>
