<template>
  <div>
    <BaseButton
      variant="salmon"
      @click="modalOpen = true"
    >
      <template slot="iconMiddle">
        <Icon
          view-box="0 0 24 24"
          class="w-5 h-5"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        >
          <Edit />
        </Icon>
      </template>
    </BaseButton>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Edit Candidate Instructions
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <EditSendExamsTemplate
        v-if="sendExamsTemplate"
        :send-exams-template="sendExamsTemplate"
        :send-exams-template-default="sendExamsTemplateDefault"
        @sendExamsTemplateUpdated="updateSendExamsTemplate"
      />
      <div
        v-else
        class="flex items-center justify-center"
        style="min-height: 605px;"
      >
        <Loader></Loader>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import EditSendExamsTemplate from '@components/Settings/EditSendExamsTemplate'
import Icon from '@components/Icons/Icon'
import Edit from '@components/Icons/Edit'
import Plus from '@components/Icons/Plus'
import Loader from '@components/Loader'

export default {
  components: {
    Modal,
    EditSendExamsTemplate,
    Icon,
    Edit,
    Plus,
    Loader
  },

  props: {
    sendExamsTemplate: {
      type: Object,
      default: null
    },
    sendExamsTemplateDefault: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      modalOpen: false
    }
  },

  mounted() {
    if (this.$route.query.candidateInstructions) {
      this.modalOpen = true
    }
  },

  methods: {
    updateSendExamsTemplate() {
      this.$emit('sendExamsTemplateUpdated')
      this.modalOpen = false
    }
  }
}
</script>
