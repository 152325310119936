<template>
  <form
    v-if="sendExamsTemplate"
    class="px-6 pt-6 pb-8 bg-gray-100"
    novalidate
    @submit.prevent
  >
    <span class="text-gray-800 text-sm">Greeting</span>
    <div class="flex items-center mt-1">
      <label class="block relative md:w-1/4">
        <input
          v-model="$v.formResponses.greeting.$model"
          type="text"
          class="form-input w-full focus:border-gray-800 focus:shadow-none resize-none"
          placeholder="e.g Hello"
          @focus="$event.target.select()"
        />
      </label>
      <div class="text-sm font-mono ml-2">
        [candidate name]
      </div>
    </div>
    <ErrorsInline v-if="errors">
      <span
        v-if="!$v.formResponses.greeting.required"
      >This field is required.</span>
    </ErrorsInline>

    <label class="block relative w-full mt-6">
      <div class="flex justify-between">
        <span class="text-gray-800 text-sm">Body</span>
        <span
          class="text-gray-600 text-xs"
          v-html="characterCounter('instructions')"
        >
        </span>
      </div>
      <textarea
        v-model="$v.formResponses.instructions.$model"
        class="form-textarea w-full focus:border-gray-800 focus:shadow-none resize-none"
        rows="6"
        :maxlength="characterLimits.instructions"
        placeholder="Instructions"
        @focus="$event.target.select()"
      />
    </label>
    <ErrorsInline v-if="errors">
      <span
        v-if="!$v.formResponses.instructions.required"
      >This field is required.</span>
    </ErrorsInline>

    <p class="mb-6 text-sm mt-4 font-mono">
      [link to the candidate’s tests]
    </p>

    <p>
      Regards,<br>
      The {{ organisationName }} Team
    </p>

    <p class="text-sm mt-6 bg-gray-300 p-2 flex items-center justify-center">
      <Icon class="mr-2 w-5 h-5 fill-current">
        <Info />
      </Icon>
      <span>All Candidates contacted by your organisation will receive this email.</span>
    </p>

    <section>
      <div class="mt-8 grid grid-cols-3 items-center">
        <div class="md:col-start-2 md:col-end-3 mx-auto">
          <BaseButton
            :loading="loading"
            @click="updateSendExamsTemplate"
          >
            Save changes
          </BaseButton>
        </div>
        <div class="col-start-3 ml-auto">
          <a
            class="text-sm underline hover:text-gray-900"
            href="javascript:;"
            @click="restoreDefault"
          >
            Restore default
          </a>
        </div>
      </div>

      <div class="mt-2 flex justify-center">
        <ErrorsInline
          v-if="empty && uiState === 'submit clicked'"
          class="text-center"
        >
          Please fill in the required fields above
        </ErrorsInline>
        <Error
          v-for="(errorMessage, index) in requestErrorMessages"
          :key="index"
          class="w-full text-center"
        >
          {{ errorMessage }}
        </Error>
      </div>
    </section>
  </form>
</template>

<script>
import Error from '@components/FormElements/Error'
import ErrorsInline from '@components/ErrorsInline'
import Icon from '@components/Icons/Icon'
import Info from '@components/Icons/Info'
import organisationsApi from '@api/organisations'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    ErrorsInline,
    Error,
    Icon,
    Info
  },

  mixins: [validationMixin],

  props: {
    sendExamsTemplate: {
      type: Object,
      default: null
    },
    sendExamsTemplateDefault: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      uiState: 'submit not clicked',
      loading: false,
      errors: false,
      requestErrorMessages: false,
      empty: true,

      formResponses: {
        greeting: '',
        instructions: ''
      },

      characterLimits: {
        instructions: '500'
      }
    }
  },

  validations: {
    formResponses: {
      greeting: {
        required
      },
      instructions: {
        required
      }
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name',
      organisationId: 'organisations/id'
    })
  },

  mounted() {
    this.formResponses = Object.assign({}, this.sendExamsTemplate ? this.sendExamsTemplate : this.sendExamsTemplateDefault)
    this.$v.formResponses.$touch()
  },

  methods: {
    characterCounter(field) {
      const formFieldsFilled = Object.values(this.formResponses).some(field => field !== '')
      if (formFieldsFilled) {
        return `<span class="w-16">${(this.characterLimits[field] - this.formResponses[field].length)}</span> / <span class="w-16">${this.characterLimits[field]}</span>`
      }
    },

    restoreDefault() {
      this.formResponses = Object.assign({}, this.sendExamsTemplateDefault)
    },

    updateSendExamsTemplate() {
      this.empty = !this.$v.formResponses.$anyDirty
      this.errors = this.$v.formResponses.$anyError
      this.uiState = 'submit clicked'
      if (this.errors === false && this.empty === false) {
        this.loading = true

        organisationsApi
          .updateSendExamsTemplate(this.organisationId, this.formResponses)
          .then((sendExamsTemplate) => {
            this.loading = false
            this.$emit('sendExamsTemplateUpdated')
          })
          .catch(error => {
            if (error.response.data.errors) {
              this.requestErrorMessages = error.response.data.errors
            } else {
              console.error(error.message)
              this.requestErrorMessages = ['Sorry, an error occured']
            }
            this.loading = false
          })
      }
    }
  }
}
</script>
