<template>
  <div>
    <header class="flex items-center justify-between mt-8">
      <div class="flex items-center">
        <h2 class="text-lg mb-8">
          Notifications
        </h2>
      </div>
    </header>

    <ul
      v-if="notifications"
      class="divide-y space-y-4"
    >
      <li
        v-for="(label, notification) in notifications"
        :key="notification"
        class="flex justify-between"
      >
        <label
          class="text-gray-800 text-sm w-2/3"
        >
          {{ label }}.
        </label>
        <div class="w-1/4 flex justify-end">
          <ToggleSwitch
            :is-checked="!state.blocked.includes(notification)"
            :disabled="processing"
            @toggle="toggle(notification)"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ToggleSwitch from '@components/FormElements/ToggleSwitch'

import employersApi from '@api/employers'
import { mapGetters } from 'vuex'

export default {
  components: {
    ToggleSwitch
  },

  data() {
    return {
      notifications: null,
      blocked: [],
      state: {
        blocked: []
      },
      processing: false
    }
  },

  computed: {
    ...mapGetters({
      employerId: 'employers/id'
    })
  },

  created() {
    this.state.blocked = this.blocked.slice(0)

    employersApi.getNotifications(this.employerId)
      .then(data => {
        this.notifications = data.notifications
        this.state.blocked = data.blocked
      })
  },

  methods: {
    submit() {
      this.processing = true
      return employersApi.postNotifications(this.employerId, this.state.blocked)
        .then(data => {
          this.processing = false
        })
    },

    /**
     * Toggle a notification on or off and submit
     *
     * @param {string} notification
     */
    toggle(notification) {
      if (this.state.blocked.includes(notification)) {
        const index = this.state.blocked.indexOf(notification)
        this.$delete(this.state.blocked, index)
        this.submit()
        return
      }

      this.state.blocked.push(notification)
      this.submit()
    }
  }
}
</script>
