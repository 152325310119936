<template>
  <BaseContainer>
    <ProfileSettings />
  </BaseContainer>
</template>

<script>
import ProfileSettings from '@components/Settings/ProfileSettings'

export default {
  page: {
    title: 'Profile'
  },

  components: {
    ProfileSettings
  }
}
</script>
