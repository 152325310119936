import countries from '@groupResources/lists/countries.json'

/**
 * Return a country name from a country code
 */
export const countryName = countryCode => {
  if (!countryCode) {
    return
  }
  const country = countries.find(country => country.value === countryCode)
  if (!country) {
    return countryCode
  }
  return country.text
}
