import { countryName } from './countryName.js'

export const companyAddress = (address) => {
  if (!address) {
    return
  }

  const orderedAddress = {
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: address.state,
    postalCode: address.postalCode,
    country: countryName(address.country)
  }
  return Object.values(orderedAddress).map(entry => entry).filter(Boolean).join('</br> ')
}
