<template>
  <div>
    <SettingsNavigation />

    <div
      v-if="hasOrganisationLoaded"
      class="max-w-screen-xl mx-auto sm:flex sm:justify-between px-6"
    >
      <ProfileCard class="mb-8 sm:w-1/2 sm:mr-8 sm:mb-0" />
      <CompanyCard class="sm:w-1/2" />
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import CompanyCard from '@components/CompanyCard'
import ProfileCard from '@components/ProfileCard'
import { mapGetters } from 'vuex'

export default {
  components: {
    SettingsNavigation,
    CompanyCard,
    ProfileCard
  },

  computed: {
    ...mapGetters({
      hasOrganisationLoaded: 'organisations/hasOrganisationLoaded'
    })
  }
}
</script>
