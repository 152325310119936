<template>
  <section class="mt-8 border-t">
    <div class="flex items-center justify-between">
      <h2 class="py-6 text-lg">
        Candidate Instructions
      </h2>

      <EditSendExamsTemplateButton
        :send-exams-template="sendExamsTemplate"
        :send-exams-template-default="sendExamsTemplateDefault"
        @sendExamsTemplateUpdated="getSendExamsTemplate()"
      />
    </div>

    <p class="mb-6 text-sm">
      <Icon
        view-box="0 0 512 512"
        class="w-5 h-5 mr-2 fill-current"
      >
        <Info />
      </Icon>
      <span>All Candidates contacted by your organisation will receive this email.</span>
    </p>

    <article
      v-if="sendExamsTemplate"
      class="p-4 space-y-4 bg-gray-100"
    >
      <p>
        {{ sendExamsTemplate.greeting }} <span class="font-mono text-sm">[candidate name]</span>
      </p>
      <p v-html="formattedBody">
      </p>
      <p class="mb-4 font-mono text-sm">
        [link to the candidate’s tests]
      </p>

      <p>
        Regards,<br>
        The {{ organisationName }} Team
      </p>
    </article>
  </section>
</template>

<script>
import EditSendExamsTemplateButton from '@components/Settings/EditSendExamsTemplateButton'
import Icon from '@components/Icons/Icon'
import Info from '@components/Icons/Info'
import { mapGetters } from 'vuex'
import organisationsApi from '@api/organisations'

export default {
  components: {
    Icon,
    Info,
    EditSendExamsTemplateButton
  },

  data() {
    return {
      sendExamsTemplate: null,
      sendExamsTemplateDefault: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'organisations/id',
      organisationName: 'organisations/name'
    }),

    formattedBody() {
      if (!this.sendExamsTemplate) {
        return
      }
      return this.sendExamsTemplate.instructions.replace(/\n/g, '<br />')
    }
  },

  mounted() {
    this.getSendExamsTemplate()
  },

  methods: {
    getSendExamsTemplate() {
      return organisationsApi
        .showSendExamsTemplate(this.organisationId)
        .then((sendExamsTemplate) => {
          const hasCustom = Object.values(sendExamsTemplate.custom).some(field => field !== '')

          if (hasCustom) {
            this.sendExamsTemplate = sendExamsTemplate.custom
          } else {
            this.sendExamsTemplate = sendExamsTemplate.default
          }

          this.sendExamsTemplateDefault = sendExamsTemplate.default
        })
    }
  }
}
</script>
