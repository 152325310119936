<template>
  <form
    class="px-6 pt-6 pb-8 bg-gray-100"
    novalidate
    @submit.prevent
  >
    <section>
      <label class="block relative">
        <span class="text-gray-800 text-sm">First name</span>
        <input
          v-model="$v.formResponses.firstName.$model"
          type="text"
          class="form-input mt-1 w-full"
          autocomplete="given-name"
          placeholder="First name"
        >
      </label>
      <ErrorsInline v-if="errors">
        <span v-if="!$v.formResponses.firstName.required">This field is required.</span>
      </ErrorsInline>
    </section>

    <section class="mt-6">
      <label class="block relative">
        <span class="text-gray-800 text-sm">Surname</span>
        <input
          v-model="$v.formResponses.surname.$model"
          type="text"
          class="form-input mt-1 w-full"
          autocomplete="family-name"
          placeholder="Surname"
        >
      </label>
      <ErrorsInline v-if="errors">
        <span v-if="!$v.formResponses.surname.required">This field is required.</span>
      </ErrorsInline>
    </section>

    <section class="mt-6">
      <label class="block relative">
        <span class="text-gray-800 text-sm">Email</span>
        <input
          v-model="$v.formResponses.email.$model"
          type="email"
          class="form-input mt-1 w-full"
          autocomplete="email"
          placeholder="Email"
        >
      </label>
      <ErrorsInline v-if="errors">
        <span v-if="!$v.formResponses.email.email">Needs to be a valid email.</span>
        <span v-if="!$v.formResponses.email.required">This field is required.</span>
      </ErrorsInline>
    </section>

    <section class="mx-auto text-center">
      <BaseButton
        class="mt-8"
        :loading="loading"
        @click="submit"
      >
        Save changes
      </BaseButton>

      <div class="mt-2 flex justify-center">
        <ErrorsInline
          v-if="empty && uiState === 'submit clicked'"
          class="text-center"
        >
          Please fill in the required fields above
        </ErrorsInline>
      </div>
    </section>
  </form>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    ErrorsInline
  },

  mixins: [validationMixin],

  data() {
    return {
      uiState: 'submit not clicked',
      loading: false,
      errors: false,
      empty: true,

      formResponses: {
        firstName: null,
        surname: null,
        email: null
      }
    }
  },

  computed: {
    ...mapGetters({
      firstName: 'employers/firstName',
      surname: 'employers/surname',
      email: 'employers/email'
    })
  },

  validations: {
    formResponses: {
      firstName: {
        required
      },
      surname: {
        required
      },
      email: {
        email,
        required
      }
    }
  },

  mounted() {
    this.formResponses.firstName = this.firstName
    this.formResponses.surname = this.surname
    this.formResponses.email = this.email
  },

  methods: {
    submit() {
      this.empty = !this.$v.formResponses.$anyDirty
      this.errors = this.$v.formResponses.$anyError
      this.uiState = 'submit clicked'

      if (this.errors === false && this.empty === false) {
        this.loading = true

        const employer = {
          firstName: this.formResponses.firstName,
          surname: this.formResponses.surname,
          email: this.formResponses.email
        }

        this.$store.dispatch('employers/update', employer)
          .then(() => {
            this.$emit('close')
            this.uiState = 'form submitted'
          })
          .catch(() => {
            this.uiState = 'submit not clicked'
          })
      }
    }
  }
}
</script>
