<template>
  <BaseCard>
    <div v-if="hasOrganisationLoaded">
      <header class="flex items-center justify-between">
        <div class="flex items-center">
          <span
            class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white font-semibold"
          >
            {{ name.charAt(0).toUpperCase() }}
          </span>
          <h2 class="text-lg">
            Your company
          </h2>
        </div>

        <EditCompanyButton />
      </header>

      <dl class="flex flex-wrap mt-5 text-sm">
        <dt class="w-1/3 py-4 text-gray-500">
          Company name
        </dt>
        <dd class="w-2/3 py-4">
          {{ name }}
        </dd>

        <dt
          class="w-1/3 py-4 text-gray-500 border-t"
        >
          VAT number
        </dt>
        <dd
          class="w-2/3 py-4 border-t"
        >
          <span v-if="vatNumber">{{ vatNumber }}</span>
        </dd>

        <dt
          class="w-1/3 py-4 text-gray-500 border-t border-b"
        >
          Address
        </dt>
        <dd
          class="w-2/3 py-4 border-t border-b"
        >
          <address
            v-if="address"
            class="not-italic"
            v-html="companyAddress(address)"
          >
          </address>
        </dd>
      </dl>

      <MaskedAttributeToggles v-if="$usesFeature('masked-attributes')" />
    </div>

    <div v-else>
      <Loader />
    </div>
  </Basecard>
</template>

<script>
import EditCompanyButton from '@components/EditCompanyButton'
import Loader from '@components/Loader'
import MaskedAttributeToggles from '@components/MaskedAttributeToggles'

import { mapGetters } from 'vuex'
import { companyAddress } from '@utils/companyAddress'

export default {
  components: {
    EditCompanyButton,
    Loader,
    MaskedAttributeToggles
  },

  data() {
    return {
      companyAddress
    }
  },

  computed: {
    ...mapGetters({
      hasOrganisationLoaded: 'organisations/hasOrganisationLoaded',
      name: 'organisations/name',
      vatNumber: 'organisations/vatNumber',
      address: 'organisations/address'
    })
  }
}
</script>
