<template>
  <div>
    <h2 class="text-lg my-8">
      Candidate attribute masking
    </h2>
    <p class="text-sm">
      Anonymize data and remove bias from your hiring process by hiding candidate information.
    </p>

    <ul
      class="divide-y space-y-4 mt-4"
    >
      <li
        v-for="(attributeForMasking, attributeForMaskingIdx) in attributesForMasking"
        :key="attributeForMaskingIdx"
        class="flex justify-between pt-4"
      >
        <label
          class="text-gray-800 text-sm w-2/3"
        >
          {{ attributesToMaskLabels(attributeForMasking) }}
        </label>
        <div class="w-1/4 flex justify-end">
          <div class="flex items-center">
            <transition
              enter-active-class="transition-all duration-500 ease-out"
              leave-active-class="transition-all duration-150 ease-in"
              enter-class="opacity-0"
              leave-class="opacity-100"
              enter-to-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <span
                v-if="attributesToMask.includes(attributeForMasking)"
                class="inline-flex items-center px-2 py-0.5 rounded text-xs bg-gray-100 text-gray-800 mr-6"
              >

                Hidden
              </span>
            </transition>
            <ToggleSwitch
              :id="attributeForMaskingIdx"
              :is-checked="!attributesToMask.includes(attributeForMasking)"
              @toggle="toggleAttributeMaskingField(attributeForMasking)"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ToggleSwitch from '@components/FormElements/ToggleSwitch'

import organisationsApi from '@api/organisations'
import { mapGetters } from 'vuex'

export default {
  components: {
    ToggleSwitch
  },

  data() {
    return {
      attributesForMasking: [
        'name',
        'email',
        'photo',
        'profileTab'
      ],
      attributesToMask: []
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'organisations/id',
      maskedAttributes: 'organisations/maskedAttributes'
    })
  },

  created() {
    this.attributesToMask = Object.assign([], this.maskedAttributes)
  },

  methods: {
    attributesToMaskLabels(label) {
      const labels = {
        'name': 'Name',
        'email': 'Email',
        'photo': 'Photo',
        'profileTab': 'Profile Tab'
      }
      return labels[label]
    },

    toggleAttributeMaskingField(field) {
      if (!this.attributesToMask.includes(field)) {
        this.attributesToMask.push(field)
      } else {
        this.attributesToMask = this.attributesToMask.filter(maskItem => maskItem !== field)
      }

      this.saveMaskedAttributes()
    },

    saveMaskedAttributes() {
      return organisationsApi.maskedAttributes(this.organisationId, this.attributesToMask)
        .then(response => {
          this.attributesToMask = response.maskedAttributes
          this.$store.dispatch('organisations/getOrganisation')
        })
        .catch(error => {
          throw error
        })
    }
  }
}
</script>
